import { LangLanguageEnum } from './enums';

export interface Language {
    languageCode: string;
    capitalizeMonths: boolean;
    capitalizeWeekdays: boolean;
    languageEnum: LangLanguageEnum;

    AndXOtherShifts_1(x:string|number) :string;
    ChatWith_1(x:string|number) :string;
    HandoverApproveRejectText_1(x:string|number) :string;
    MessageModal_1(x:string|number) :string;
    MessageModalRead_1(x:string|number) :string;
    MessageModalUnread_1(x:string|number) :string;
    MissingSwapGroup_1(x:string|number) :string;
    NumApplicantsFromShiftExchange_1(x:string|number) :string;
    OfferShiftTo_1(x:string|number) :string;
    ConfirmShiftExchangeContent_2(x:string|number, y:string|number) :string;
    RegisteredAbsent_2(x:string|number, y:string|number) :string;
    SwapApproveRejectText_2(x:string|number, y:string|number) :string;
    absenceType :string;
    accept :string;
    Activities :string;
    additionalInformation :string;
    AddNote :string;
    Alert :string;
    all :string;
    allEmployees :string;
    AllowNotifications :string;
    And1OtherShift :string;
    appInformation :string;
    Applicants :string;
    Approve :string;
    ApproveRejectHandover :string;
    ApproveRejectSwap :string;
    assignShift :string;
    auditActionAcceptShiftExchange :string;
    auditActionCalendar :string;
    auditActionCancelExchange :string;
    auditActionChangeTeam :string;
    ChangeTeam :string;
    auditActionInit :string;
    auditActionSelectShift :string;
    auditActionToggleAllowNotifications :string;
    AvailableFictivePerson :string;
    AwaitingApproveHandover :string;
    AwaitingApproveSwap :string;
    Back :string;
    back :string;
    callList :string;
    Cancel :string;
    CancelOffer :string;
    cancelOfferOnShiftExchange :string;
    caretDown :string;
    ChangeOrganization :string;
    ChangePersonelGroup :string;
    Changes :string;
    Chat :string;
    ChatAndInternalMessages :string;
    ChatWithEmployee :string;
    check :string;
    chooseAbsenceType :string;
    ChooseApplicants :string;
    ChooseGroups :string;
    chooseLanguage :string;
    chooseTeam :string;
    clear :string;
    Close :string;
    ConfirmCancellationOfOffer :string;
    ConfirmCancellationOfOfferContent :string;
    ConfirmLogout :string;
    ConfirmMarkAsHandled :string;
    ConfirmMarkAsHandledContent :string;
    ConfirmOfferOnShiftExchange :string;
    ConfirmOfferOnShiftExchangeContent :string;
    ConfirmShiftAssignment :string;
    ConfirmShiftAssignmentContent :string;
    ConfirmShiftExchange :string;
    Copy :string;
    CopyDetails :string;
    CopyErrorTextAndContactSupport :string;
    Date :string;
    Delete :string;
    Duties :string;
    Employee :string;
    EndDate :string;
    AddEndDate :string;
    enterPassword :string;
    enterUserName :string;
    Error :string;
    ErrorCode :string;
    exchange :string;
    exchangeCandidateFilter :string;
    absence_plural :string;
    candidates :string;
    candidates_short :string;
    exchangeCandidateFilter_callinEsque :string;
    exchangeCandidateFilter_classic :string;
    exchangeCandidateFilter_noCallin :string;
    expectedToBeBack :string;
    Eye :string;
    FetchData :string;
    FictiveEmployees :string;
    FictivePerson :string;
    findSubstitute :string;
    FollowGroup :string;
    FormerAssignee :string;
    From :string;
    GoToSwap :string;
    Group :string;
    Groups :string;
    GroupsFollowed :string;
    Hello :string;
    HhMmSeparator :string;
    HowManyRead :string;
    HowManyUnread :string;
    HttpStatus :string;
    Info :string;
    InternalError :string;
    InternalMessage :string;
    InternalMessages :string;
    KeepExchange :string;
    KeepOffer :string;
    Language :string;
    languageName :string;
    LeftArrow :string;
    Loading :string;
    LoadingShifts :string;
    loadMoreDays :string;
    Location :string;
    Lock :string;
    login :string;
    LoginLoadingData :string;
    loginTitleWelcome :string;
    Logout :string;
    mail :string;
    ManyPersonGroupsContent :string;
    ManyPersonGroupsTitle :string;
    markAsHandled :string;
    MaxLengthError :string;
    MeAlt :string;
    Menu :string;
    MessagesEmptyStateDescription_1(x:string|number) :string;
    MessagesEmptyStateTitle :string;
    MomentLoadingData :string;
    Month_Array :string[];
    netError :string;
    NetworkErrorOccured :string;
    New :string;
    Next :string;
    No :string;
    NoAccessToTeamPlan :string;
    noAppInformation :string;
    NoCandidates :string;
    NoCandidatesLong :string;
    NoChanges :string;
    NoChangesHaveBeenMade_2(x:string|number, y:string|number) :string;
    noDepartmentsSelected :string;
    noDepartmentsToShow :string;
    noEmployeeToShow :string;
    NoOffersInSearch :string;
    NoPersonelGroupFollowedTextMobile :string;
    NoPersonelGroupFollowedTextWeb :string;
    NoPersonelGroupFollowedTitle :string;
    Norm :string;
    noShiftsToShow :string;
    noSortedCallListToShow :string;
    NotAssignable :string;
    Note :string;
    NothingPlanned :string;
    NothingPlannedForGroup_2(x:string|number, y:string|number) :string;
    NothingToTakeActionOn_2(x:string|number, y:string|number) :string;
    IsNowCreated_1(x:string|number) :string;
    notification :string;
    NotificationsOff :string;
    NotificationsOn :string;
    NoUnresolvedIssues :string;
    Offer :string;
    offerOnShiftExchange :string;
    OfferShift :string;
    ongoingAbsence :string;
    OriginalBelongingTo :string;
    OtherEmployees :string;
    OtherGroups :string;
    Overview :string;
    password :string;
    passwordIsRequired :string;
    Pending :string;
    PersonelGroupInfoBody1 :string;
    PersonelGroupInfoBody2NonTouch :string;
    PersonelGroupInfoBody2Touch :string;
    PersonelGroupInfoBodyTouchSymbolExplanation :string;
    PersonelGroupInfoBodyTouchSymbolExplanationFetchData :string;
    PersonelGroupInfoBodyTouchSymbolExplanationUnreadChat :string;
    PersonelGroupInfoBodyTouchSymbolExplanationUnresolved :string;
    PersonelGroupInfoTitle :string;
    PersonGroups :string;
    PersonGroupsNotLoaded :string;
    phone :string;
    Planned :string;
    Planner :string;
    Problem :string;
    Read :string;
    ReadMessagesAndNotes :string;
    ReadPluralPastTense :string;
    RegardingShort :string;
    registerAbsence :string;
    Reject :string;
    RejectShiftAssignment :string;
    RejectShiftAssignmentContent :string;
    RejectShiftExchange :string;
    RejectShiftExchangeContent :string;
    reload :string;
    ResponseFromServer :string;
    RightArrow :string;
    RuleBreak :string;
    RuleViolationInfoApplicants :string;
    RuleViolationInfoSortedCallList :string;
    search :string;
    settings :string;
    shift_plural :string;
    ShiftStatusAbsent :string;
    ShiftStatusActionRequired :string;
    ShiftStatusNoActivities :string;
    ShiftStatusOffered :string;
    ShiftStatusPlanned :string;
    ShiftStatusReplaced :string;
    ShiftStatusSubstitute :string;
    somethingWentWrong :string;
    Status :string;
    Tasks :string;
    Team :string;
    teamPlan :string;
    TheErrorMessageWasCopied :string;
    To :string;
    today :string;
    Type :string;
    UnfollowGroup :string;
    Unread :string;
    UnreadMessagesAndNotes :string;
    UnreadPluralPastTense :string;
    Url :string;
    User :string;
    userName :string;
    userNameIsRequired :string;
    userNameNoBlackSpacesAtStartOrEnd :string;
    VersionNotSupported :string;
    Week :string;
    Weekday_Array :string[];
    WeekdayInitials_Array :string[];
    Weeks :string;
    WhereToFindDepartments :string;
    WriteMessage :string;
    Yes :string;
    YesLogout :string;
    YourAppIsHavingProblemsWithTheServer :string;
    ConfirmLeavePage :string;
    ConfirmLeavePageContent :string;
    YesLeavePage :string;
    ChoosePredefined :string;
    CreateNewShift :string;
    CreateNew :string;
    Create :string;
    RuleViolationInfo :string;
    NoChangesHaveBeenMade :string;
    HowMany :string;
    UnfollowDialogTitle :string;
    UnfollowDialogBody_1(x:string|number) :string;
    TooManyGroupsAlertTitle :string;
    TooManyGroupsAlertBody :string;
    ConfirmOfferOnShiftExchangeCandidateFilter :string;
    ConfirmOfferOnShiftExchangeCandidateFilter_1(x:string|number) :string;
    ConfirmOfferOnShiftExchangeAllAvailable :string;
    ConfirmOfferOnShiftExchangeAllAvailable_1(x:string|number) :string;
    ConfirmOfferExchangeCandidatesHelperText :string;
    FilterOutEmployeesWith :string;
    responsibility :string;
    ConfirmRemoveShiftTitle :string;
    ConfirmRemoveShiftBody_2(x:string|number, y:string|number) :string;
    ConfirmRemoveShiftBodyHandover :string;
    RemoveShiftToast_3(x:string|number, y:string|number, z:string|number) :string;
    DeviatingPayerNotificationTitle :string;
    DeviatingPayerNotificationBody_1(x:string|number) :string;
    FindEmployee :string;
    IsNowAssigned_2(x:string|number, y:string|number) :string;
    StarShift :string;
    RecentlyUsedShiftDefs :string;
    NoShiftMatch :string;
    SelectLanguage :string;
    SelectShiftType :string;
    SelectAbsenceType :string;
    SelectAbsenceBack :string;
    SelectThePersonGroup :string;
}

const Language_En: Language = {
    languageCode: "En",
    capitalizeMonths: true,
    capitalizeWeekdays: true,
    languageEnum: LangLanguageEnum.En,
AndXOtherShifts_1: (x:string|number) => { return "+ " + x + " other shifts."; },
ChatWith_1: (x:string|number) => { return "Chat with " + x + ""; },
HandoverApproveRejectText_1: (x:string|number) => { return "Choose the applicant who should get " + x + "'s shift."; },
MessageModal_1: (x:string|number) => { return "As a starting point, TeamPlan retrieves unread messages for the next " + x + " days. This includes both chat and internal messages.\n\nIf, on the 'Overview' page, you have entered shifts that are outside this period, the unread messages of this period will also be displayed here."; },
MessageModalRead_1: (x:string|number) => { return "As a starting point, TeamPlan retrieves read messages for the next " + x + " days. This includes both chat and internal messages.\n\nIf, on the 'Overview' page, you have entered shifts that are outside this period, the read messages of this period will also be displayed here."; },
MessageModalUnread_1: (x:string|number) => { return "As a starting point, TeamPlan retrieves unread messages for the next " + x + " days. This includes both chat and internal messages.\n\nIf, on the 'Overview' page, you have entered shifts that are outside this period, the unread messages of this period will also be displayed here."; },
MissingSwapGroup_1: (x:string|number) => { return "" + x + " has no swap group"; },
NumApplicantsFromShiftExchange_1: (x:string|number) => { return "" + x + " applicants from shift exchange"; },
OfferShiftTo_1: (x:string|number) => { return "Offer shift to " + x + ""; },
ConfirmShiftExchangeContent_2: (x:string|number, y:string|number) => { return "Are you sure you want to approve the shift exchange between " + x + " and " + y + "?"; },
RegisteredAbsent_2: (x:string|number, y:string|number) => { return "Please note that you have also registered " + x + " as absent: " + y + ""; },
SwapApproveRejectText_2: (x:string|number, y:string|number) => { return "" + x + " and " + y + " would like to swap shifts."; },
absenceType: "Absence type",
accept: "Accept",
Activities: "Activities",
additionalInformation: "Additional information",
AddNote: "Internal messages",
Alert: "Advarsel",
all: "All",
allEmployees: "All employees",
AllowNotifications: "Allow notifications",
And1OtherShift: "+ 1 other shift.",
appInformation: "App Information",
Applicants: "Applicants",
Approve: "Approve",
ApproveRejectHandover: "Approve/Reject hand over",
ApproveRejectSwap: "Approve/Reject shift swap",
assignShift: "Assign shift",
auditActionAcceptShiftExchange: "Accept shift exchange",
auditActionCalendar: "Calendar",
auditActionCancelExchange: "Cancel shift exchange",
auditActionChangeTeam: "Change person group",
ChangeTeam: "Change team",
auditActionInit: "init",
auditActionSelectShift: "Select shift",
auditActionToggleAllowNotifications: "Toggle allow notifications",
AvailableFictivePerson: "Available fictive person",
AwaitingApproveHandover: "Awaiting approval of hand over",
AwaitingApproveSwap: "Awaiting approval of shift swap",
Back: "Back",
back: "Back",
callList: "Sorted call list",
Cancel: "Cancel",
CancelOffer: "Cancel offer",
cancelOfferOnShiftExchange: "Cancel offer on shift exchange",
caretDown: "Down arrow",
ChangeOrganization: "Change organization",
ChangePersonelGroup: "Change person group",
Changes: "Changes",
Chat: "Chat",
ChatAndInternalMessages: "Chat and internal messages",
ChatWithEmployee: "Chat with employee",
check: "Check",
chooseAbsenceType: "Choose absence type",
ChooseApplicants: "Choose applicants",
ChooseGroups: "Choose the personnel groups to whom you want to offer the shift.",
chooseLanguage: "Choose language",
chooseTeam: "Choose person group",
clear: "Clear",
Close: "Close",
ConfirmCancellationOfOffer: "Confirm cancellation of offer",
ConfirmCancellationOfOfferContent: "Are you sure you want to cancel the offer of this shift for all personnel?",
ConfirmLogout: "Are you sure you want to log out?",
ConfirmMarkAsHandled: "Confirm mark as handled",
ConfirmMarkAsHandledContent: "Are you sure you want to mark this as handled without finding a replacement?",
ConfirmOfferOnShiftExchange: "Confirm offer on shift exchange",
ConfirmOfferOnShiftExchangeContent: "Are you sure you want to offer this shift on the shift exchange for all available personnel?",
ConfirmShiftAssignment: "Confirm shift assignment",
ConfirmShiftAssignmentContent: "Are you sure you want to assign this shift to",
ConfirmShiftExchange: "Confirm approval of exchange",
Copy: "Copy",
CopyDetails: "Copy details",
CopyErrorTextAndContactSupport: "Help to solve the issue. Copy the details below and send them to IT support at your workplace",
Date: "Date",
Delete: "Delete",
Duties: "Duties",
Employee: "Employee",
EndDate: "End date",
AddEndDate: "Add end date",
enterPassword: "Enter password",
enterUserName: "Enter username",
Error: "Error",
ErrorCode: "Error code",
exchange: "Exchange",
exchangeCandidateFilter: "Candidate filter",
absence_plural: "Absence",
candidates: "Candidates",
candidates_short: "Cand..",
exchangeCandidateFilter_callinEsque: "Shifts and protected absence (more cand..)",
exchangeCandidateFilter_classic: "Shifts and absence (fewest candidates)",
exchangeCandidateFilter_noCallin: "Protected absence (most candidates)",
expectedToBeBack: "Expected to be back",
Eye: "Eye",
FetchData: "Fetch data without opening",
FictiveEmployees: "Fictive employees",
FictivePerson: "Fictive person",
findSubstitute: "Find substitute",
FollowGroup: "Follow group",
FormerAssignee: "Former Assignee",
From: "From",
GoToSwap: "Go to the shift exchange",
Group: "Group",
Groups: "Groups",
GroupsFollowed: "Groups you follow",
Hello: "Hello",
HhMmSeparator: ":",
HowManyRead: "How many read have been loaded?",
HowManyUnread: "How many unread have been loaded?",
HttpStatus: "HTTP status",
Info: "Information",
InternalError: "An unexpected error occured. The error has been reported to our operations department.",
InternalMessage: "Internal message",
InternalMessages: "Internal messages",
KeepExchange: "Keep exchange",
KeepOffer: "Keep offer",
Language: "Language",
languageName: "English",
LeftArrow: "Left arrow",
Loading: "Loading",
LoadingShifts: "Loading shifts",
loadMoreDays: "Load more days",
Location: "Location",
Lock: "Lock",
login: "Log in",
LoginLoadingData: "Just a moment,\nyour app is loading data",
loginTitleWelcome: "Welcome to",
Logout: "Logout",
mail: "Mail",
ManyPersonGroupsContent: "You have access to more than 10 person groups, and therefore the app loads data for a person group when it is opened.",
ManyPersonGroupsTitle: "Many person groups",
markAsHandled: "Mark as handled",
MaxLengthError: "You cannot type any more characters",
MeAlt: "Me",
Menu: "Menu",
MessagesEmptyStateDescription_1: (x:string|number) => { return "No messages have been received or sent in the staff group that relate to the next " + x + " days."; },
MessagesEmptyStateTitle: "No messages",
MomentLoadingData: "Just a moment, we're loading data",
Month_Array: ["January","February","March","April","May","June","July","August","September","October","November","December"],
netError: "Sorry, there is a problem with the network, status:",
NetworkErrorOccured: "A network error occured",
New: "New",
Next: "Next",
No: "No",
NoAccessToTeamPlan: "Unfortunately, you do not have access to TeamPlan.",
noAppInformation: "No App Information found",
NoCandidates: "No ideal candidates",
NoCandidatesLong: "Unfortunately, there are no ideal candidates to show in the sorted call list.",
NoChanges: "No changes",
NoChangesHaveBeenMade_2: (x:string|number, y:string|number) => { return "No shifts have been changed for the period\n" + x + " - " + y + ""; },
noDepartmentsSelected: "No person groups selected",
noDepartmentsToShow: "No person groups to show",
noEmployeeToShow: "No employee to show",
NoOffersInSearch: "There is no groups that match your search.",
NoPersonelGroupFollowedTextMobile: "Swipe left on a group and choose",
NoPersonelGroupFollowedTextWeb: "Hover over a group and press the three dots to follow.",
NoPersonelGroupFollowedTitle: "You are not following any groups",
Norm: "Norm",
noShiftsToShow: "No shifts with unread messages to show",
noSortedCallListToShow: "No candidates in the call list",
NotAssignable: "Not assignable",
Note: "Note",
NothingPlanned: "Nothing planned",
NothingPlannedForGroup_2: (x:string|number, y:string|number) => { return "There are no scheduled shifts for the period\n" + x + " - " + y + ""; },
NothingToTakeActionOn_2: (x:string|number, y:string|number) => { return "There are no pending issues for the period\n" + x + " - " + y + ""; },
IsNowCreated_1: (x:string|number) => { return "" + x + " is now created."; },
notification: "Notification",
NotificationsOff: "Disable notifications",
NotificationsOn: "Enable notifications",
NoUnresolvedIssues: "No unresolved issues",
Offer: "Offer",
offerOnShiftExchange: "Offer on shift exchange",
OfferShift: "Offer shift",
ongoingAbsence: "Ongoing Absence",
OriginalBelongingTo: "Original belonging to",
OtherEmployees: "Other employees",
OtherGroups: "Other groups",
Overview: "Overview",
password: "Password",
passwordIsRequired: "Password is required",
Pending: "Pending",
PersonelGroupInfoBody1: "Are you using a group often, it can be a good idea to follow it. Then the app will automatically fetch data and you will receive notifications.",
PersonelGroupInfoBody2NonTouch: "Hover the mouse above a group and click the 3 dots to follow, unfollow or enable/disable notifications",
PersonelGroupInfoBody2Touch: "Swipe left on a group, to follow or enable/disable notifications.",
PersonelGroupInfoBodyTouchSymbolExplanation: "Symbol explanation:",
PersonelGroupInfoBodyTouchSymbolExplanationFetchData: "Fetch data for a group without opening it",
PersonelGroupInfoBodyTouchSymbolExplanationUnreadChat: "Unread chat and internal messages",
PersonelGroupInfoBodyTouchSymbolExplanationUnresolved: "Pending",
PersonelGroupInfoTitle: "Fetch and follow person groups",
PersonGroups: "Person groups",
PersonGroupsNotLoaded: "Person groups whose data are not loaded are marked with the icon: ",
phone: "Phone",
Planned: "Planned",
Planner: "Planner",
Problem: "Sorry, there's a problem.",
Read: "Read",
ReadMessagesAndNotes: "Read chat and internal messages",
ReadPluralPastTense: "Read",
RegardingShort: "On",
registerAbsence: "Register absence",
Reject: "Reject",
RejectShiftAssignment: "Confirm cancellation of offer",
RejectShiftAssignmentContent: "Are you sure you want to cancel the offer on this shift?",
RejectShiftExchange: "Confirm rejection of exchange",
RejectShiftExchangeContent: "Are you sure you want to reject this shift exchange?",
reload: "Reload",
ResponseFromServer: "Response from server",
RightArrow: "Right arrow",
RuleBreak: "Rule violation",
RuleViolationInfoApplicants: "The list of applicants, employees are sorted by the severity of the rule violation occuring when a given employee is assigned the shift.\n\nThe severity of the rule violation is indicated by a number. The higher the number, the higher the severity.\n\n1 means that no rule violation will occur.",
RuleViolationInfoSortedCallList: "In the sorted call list employees are sorted by the severity of the rule violation occuring when a given employee is assigned the shift.\n\nThe severity of the rule violation is indicated by a number. The higher the number, the higher the severity.\n\n1 means that no rule violation will occur.",
search: "Search",
settings: "Settings",
shift_plural: "Shifts",
ShiftStatusAbsent: "Absent",
ShiftStatusActionRequired: "Action required",
ShiftStatusNoActivities: "Planned work w/o activities",
ShiftStatusOffered: "Offered",
ShiftStatusPlanned: "Planned work",
ShiftStatusReplaced: "Replaced",
ShiftStatusSubstitute: "Substitute",
somethingWentWrong: "Something went wrong",
Status: "Status",
Tasks: "Tasks",
Team: "Person groups",
teamPlan: "TeamPlan",
TheErrorMessageWasCopied: "The error message was copied to the clipboard",
To: "To",
today: "Today",
Type: "Type",
UnfollowGroup: "Unfollow group",
Unread: "Unread",
UnreadMessagesAndNotes: "Unread chat and internal messages",
UnreadPluralPastTense: "Unread",
Url: "URL",
User: "User",
userName: "Username",
userNameIsRequired: "Username is required",
userNameNoBlackSpacesAtStartOrEnd: "Your username cannot start or end with spaces",
VersionNotSupported: "Version not supported",
Week: "Week",
Weekday_Array: ["Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"],
WeekdayInitials_Array: ["M","T","W","T","F","S","S"],
Weeks: "Weeks",
WhereToFindDepartments: "You will find the person groups under \"Change team\" at the bottom of the screen.",
WriteMessage: "Write a message",
Yes: "Yes",
YesLogout: "Yes, log out",
YourAppIsHavingProblemsWithTheServer: "Your app is having problems with the server",
ConfirmLeavePage: "Are you sure you want to leave the page?",
ConfirmLeavePageContent: "If you leave the page now, then the shift that you were creating will be deleted.",
YesLeavePage: "Yes, leave page",
ChoosePredefined: "Choose a predefined shift",
CreateNewShift: "Create new shift",
CreateNew: "Create new",
Create: "Create",
RuleViolationInfo: "In the sorted call list employees are sorted by the severity of the rule violation occuring when a given employee is assigned the shift.\n\nThe severity of the rule violation is indicated by a number. The higher the number, the higher the severity.\n\n1 means that no rule violation will occur.",
NoChangesHaveBeenMade: "No shifts have been changed for this person group.",
HowMany: "How many unread are being loaded?",
UnfollowDialogTitle: "Unfollow group",
UnfollowDialogBody_1: (x:string|number) => { return "Are you sure, that you no longer wish to follow the group \"" + x + "\"?"; },
TooManyGroupsAlertTitle: "No more room to follow any more groups",
TooManyGroupsAlertBody: "For optimal performance, there is a limit on the number of employees the app can fetch data from simultaneously.\n\nTherefore, you need to free up space by removing one of the existing groups if you want to add a new one.\n\nPlease note that it might be necessary to remove several small groups to make room for a larger one.",
ConfirmOfferOnShiftExchangeCandidateFilter: "Are you sure you want to offer the shift in the selected personel group?",
ConfirmOfferOnShiftExchangeCandidateFilter_1: (x:string|number) => { return "Are you sure you want to offer the shift in the " + x + " selected personel groups?"; },
ConfirmOfferOnShiftExchangeAllAvailable: "Are you sure you want to offer the shift to all available staff in the selected personel group?",
ConfirmOfferOnShiftExchangeAllAvailable_1: (x:string|number) => { return "Are you sure you want to offer the shift to all available staff in the " + x + " selected personel groups?"; },
ConfirmOfferExchangeCandidatesHelperText: "Filter which employees are not offered the shift.",
FilterOutEmployeesWith: "Filter out employees with...",
responsibility: "Responsibility",
ConfirmRemoveShiftTitle: "Delete shift",
ConfirmRemoveShiftBody_2: (x:string|number, y:string|number) => { return "Are you sure that you would like to delete \"" + x + "\" " + y + ""; },
ConfirmRemoveShiftBodyHandover: "The offer on the shift exchange is also canceled as a result.",
RemoveShiftToast_3: (x:string|number, y:string|number, z:string|number) => { return "" + x + "'s shift \"" + y + "\" " + z + " is now deleted."; },
DeviatingPayerNotificationTitle: "Deviating workplace",
DeviatingPayerNotificationBody_1: (x:string|number) => { return "Please note that this shift is registered at \"" + x + "\"."; },
FindEmployee: "Find employee",
IsNowAssigned_2: (x:string|number, y:string|number) => { return "" + x + " is now assigned to " + y + "."; },
StarShift: "* shift",
RecentlyUsedShiftDefs: "Recently used",
NoShiftMatch: "There are no shifts\nthat matches your search.",
SelectLanguage: "Select language",
SelectShiftType: "Select shift type",
SelectAbsenceType: "Select absence type",
SelectAbsenceBack: "Select date",
SelectThePersonGroup: "Select the person group",
};

const Language_Da: Language = {
    languageCode: "Da",
    capitalizeMonths: false,
    capitalizeWeekdays: false,
    languageEnum: LangLanguageEnum.Da,
AndXOtherShifts_1: (x:string|number) => { return "+ " + x + " andre vagter."; },
ChatWith_1: (x:string|number) => { return "Chat med " + x + ""; },
HandoverApproveRejectText_1: (x:string|number) => { return "Vælg den ansøger der skal have " + x + "s vagt."; },
MessageModal_1: (x:string|number) => { return "Som udgangspunkt henter TeamPlan ulæste beskeder for de kommende " + x + " dage. Dette inkluderer både chat og interne beskeder.\n\nHar du på siden ‘Overblik’ indlæst vagter der ligger udenfor dette tidsrum, vil denne periodes ulæste beskeder også vises her."; },
MessageModalRead_1: (x:string|number) => { return "Som udgangspunkt henter TeamPlan læste beskeder for de kommende " + x + " dage. Dette inkluderer både chat og interne beskeder.\n\nHar du på siden ‘Overblik’ indlæst vagter der ligger udenfor dette tidsrum, vil denne periodes læste beskeder også vises her."; },
MessageModalUnread_1: (x:string|number) => { return "Som udgangspunkt henter TeamPlan ulæste beskeder for de kommende " + x + " dage. Dette inkluderer både chat og interne beskeder.\n\nHar du på siden ‘Overblik’ indlæst vagter der ligger udenfor dette tidsrum, vil denne periodes ulæste beskeder også vises her."; },
MissingSwapGroup_1: (x:string|number) => { return "" + x + " mangler en byttegruppe"; },
NumApplicantsFromShiftExchange_1: (x:string|number) => { return "" + x + " ansøgere fra vagtbørs"; },
OfferShiftTo_1: (x:string|number) => { return "Udbyd vagt til " + x + ""; },
ConfirmShiftExchangeContent_2: (x:string|number, y:string|number) => { return "Er du sikker på, at du vil godkende vagtbytte mellem " + x + " og " + y + "?"; },
RegisteredAbsent_2: (x:string|number, y:string|number) => { return "Vær opmærksom på at du også har registreret " + x + " fraværende: " + y + ""; },
SwapApproveRejectText_2: (x:string|number, y:string|number) => { return "" + x + " og " + y + " vil gerne bytte vagt."; },
absenceType: "Fraværstype",
accept: "Accepter",
Activities: "Aktiviteter",
additionalInformation: "Supplerende information",
AddNote: "Interne beskeder",
Alert: "Alert",
all: "Alle",
allEmployees: "Alle medarbejdere",
AllowNotifications: "Tillad notifikationer",
And1OtherShift: "+ 1 anden vagt.",
appInformation: "App Information",
Applicants: "Ansøgere",
Approve: "Godkend",
ApproveRejectHandover: "Godkend/Afvis overdragelse",
ApproveRejectSwap: "Godkend/Afvis vagtbytte",
assignShift: "Tildel vagt",
auditActionAcceptShiftExchange: "Accepter vagtbytte",
auditActionCalendar: "Kalender",
auditActionCancelExchange: "Fortryd vagtbytte",
auditActionChangeTeam: "Skift personalegruppe",
ChangeTeam: "Skift gruppe",
auditActionInit: "init",
auditActionSelectShift: "Vælg vagt",
auditActionToggleAllowNotifications: "Skift tillad Notifikationer",
AvailableFictivePerson: "Ledig fiktiv person",
AwaitingApproveHandover: "Afventer godkendelse af overdragelse",
AwaitingApproveSwap: "Afventer godkendelse af vagtbytte",
Back: "Tilbage",
back: "Tilbage",
callList: "Sorteret tilkaldeliste",
Cancel: "Fortryd",
CancelOffer: "Afbryd udbud",
cancelOfferOnShiftExchange: "Afbryd udbud på vagtbørs",
caretDown: "Pil ned",
ChangeOrganization: "Skift organisation",
ChangePersonelGroup: "Skift personalegruppe",
Changes: "Ændringer",
Chat: "Chat",
ChatAndInternalMessages: "Chat og interne beskeder",
ChatWithEmployee: "Chat med medarbejder",
check: "Flueben",
chooseAbsenceType: "Vælg fraværstype",
ChooseApplicants: "Vælg ansøgere",
ChooseGroups: "Vælg den eller de personalegrupper du vil udbyde vagten til.",
chooseLanguage: "Vælg sprog",
chooseTeam: "Vælg personalegruppe",
clear: "Ryd",
Close: "Luk",
ConfirmCancellationOfOffer: "Bekræft afbrydelse af udbud",
ConfirmCancellationOfOfferContent: "Er du sikker på du vil afbryde udbudet af denne vagt til alt personale?",
ConfirmLogout: "Vil du logge ud?",
ConfirmMarkAsHandled: "Bekræft accept uden afløser",
ConfirmMarkAsHandledContent: "Er du sikker på du vil acceptere fravær uden at finde en afløser?",
ConfirmOfferOnShiftExchange: "Bekræft udbud af vagt",
ConfirmOfferOnShiftExchangeContent: "Er du sikker på du vil udbyde vagten til alt ledigt personale?",
ConfirmShiftAssignment: "Bekræft tildeling af vagt",
ConfirmShiftAssignmentContent: "Er du sikker på du vil tildele vagten til",
ConfirmShiftExchange: "Bekræft godkendelse af bytte",
Copy: "Kopier",
CopyDetails: "Kopier detaljer",
CopyErrorTextAndContactSupport: "Hjælp med at løse problemet. Kopier detaljerne herunder og send dem til IT-support på din arbejdsplads.",
Date: "Dato",
Delete: "Slet",
Duties: "Tjenester",
Employee: "Employee",
EndDate: "Slutdato",
AddEndDate: "Tilføj slutdato",
enterPassword: "Skriv adgangskode",
enterUserName: "Skriv brugernavn",
Error: "Fejl",
ErrorCode: "Fejlkode",
exchange: "Byt",
exchangeCandidateFilter: "Kandidatfilter",
absence_plural: "Fravær",
candidates: "Kandidater",
candidates_short: "Kand..",
exchangeCandidateFilter_callinEsque: "Vagter og beskyttet fravær (flere kand..)",
exchangeCandidateFilter_classic: "Vagter og fravær (færrest kandidater)",
exchangeCandidateFilter_noCallin: "Beskyttet fravær (flest kandidater)",
expectedToBeBack: "Forventet tilbage",
Eye: "Øje",
FetchData: "Hent data uden at åbne",
FictiveEmployees: "Fiktive medarbejdere",
FictivePerson: "Fiktiv person",
findSubstitute: "Find afløser",
FollowGroup: "Følg gruppe",
FormerAssignee: "Tidligere tildelt",
From: "Fra",
GoToSwap: "Gå til vagtbytte",
Group: "Gruppe",
Groups: "Grupper",
GroupsFollowed: "Grupper du følger",
Hello: "Hej",
HhMmSeparator: ".",
HowManyRead: "Hvor mange læste hentes?",
HowManyUnread: "Hvor mange ulæste hentes?",
HttpStatus: "HTTP status",
Info: "Information",
InternalError: "Der skete en uventet fejl. Fejlen er rapporteret til vores driftsafdeling.",
InternalMessage: "Intern besked",
InternalMessages: "Interne beskeder",
KeepExchange: "Behold byt",
KeepOffer: "Behold udbud",
Language: "Sprog",
languageName: "Dansk",
LeftArrow: "Venstre pil",
Loading: "Indlæser",
LoadingShifts: "Henter vagter",
loadMoreDays: "Indlæs flere dage",
Location: "Sted",
Lock: "Lås",
login: "Log ind",
LoginLoadingData: "Lige et øjeblik,\ndin app henter data",
loginTitleWelcome: "Velkommen til",
Logout: "Log ud",
mail: "Mail",
ManyPersonGroupsContent: "Da du har adgang til mere end 10 personalegrupper, henter appen først data fra en personalegruppe når denne tilgåes.",
ManyPersonGroupsTitle: "Mange personalegrupper",
markAsHandled: "Accepter uden afløser",
MaxLengthError: "Du kan ikke skrive flere tegn",
MeAlt: "Mig",
Menu: "Menu",
MessagesEmptyStateDescription_1: (x:string|number) => { return "Der er ikke modtaget eller sendt nogen beskeder i personalegruppen, der vedrører de kommende " + x + " dage."; },
MessagesEmptyStateTitle: "Ingen beskeder",
MomentLoadingData: "Øjeblik, vi henter data",
Month_Array: ["Januar","Februar","Marts","April","Maj","Juni","Juli","August","September","Oktober","November","December"],
netError: "Beklager, der er et netværksproblem, status:",
NetworkErrorOccured: "Der skete en netværksfejl",
New: "Ny",
Next: "Næste",
No: "Nej",
NoAccessToTeamPlan: "Du har desværre ikke adgang til TeamPlan.",
noAppInformation: "Ingen App Information fundet",
NoCandidates: "Ingen oplagte kandidater",
NoCandidatesLong: "Der er desværre ingen kandidater at vise i den sorterede tilkaldeliste.",
NoChanges: "Ingen ændringer",
NoChangesHaveBeenMade_2: (x:string|number, y:string|number) => { return "Der er ikke ændret nogen vagter i perioden\n" + x + " - " + y + ""; },
noDepartmentsSelected: "Ingen personalegruppe valgt",
noDepartmentsToShow: "Ingen personalegrupper at vise",
noEmployeeToShow: "Ingen ansat at vise",
NoOffersInSearch: "Der er ingen personalegrupper der matcher din søgning.",
NoPersonelGroupFollowedTextMobile: "Swipe til venstre på en gruppe og vælg",
NoPersonelGroupFollowedTextWeb: "Hold musen hen over en gruppe og tryk på de tre prikker, for at følge.",
NoPersonelGroupFollowedTitle: "Du følger ingen grupper",
Norm: "Norm",
noShiftsToShow: "Ingen vagter med ulæste beskeder",
noSortedCallListToShow: "Ingen kandidater i tilkaldelisten",
NotAssignable: "Kan ikke tildeles",
Note: "Note",
NothingPlanned: "Intet planlagt",
NothingPlannedForGroup_2: (x:string|number, y:string|number) => { return "Der er ingen planlagte vagter i perioden\n" + x + " - " + y + ""; },
NothingToTakeActionOn_2: (x:string|number, y:string|number) => { return "Der er ingen udeståender i perioden\n" + x + " - " + y + ""; },
IsNowCreated_1: (x:string|number) => { return "" + x + " er nu oprettet."; },
notification: "Notifikation",
NotificationsOff: "Slå notifikationer fra",
NotificationsOn: "Slå notifikationer til",
NoUnresolvedIssues: "Ingen udeståender",
Offer: "Udbyd",
offerOnShiftExchange: "Udbyd på vagtbørs",
OfferShift: "Udbyd vagt",
ongoingAbsence: "Løbende fravær",
OriginalBelongingTo: "Oprindelig tilhørende",
OtherEmployees: "Øvrige medarbejdere",
OtherGroups: "Øvrige grupper",
Overview: "Overblik",
password: "Adgangskode",
passwordIsRequired: "Password skal udfyldes",
Pending: "Udestående",
PersonelGroupInfoBody1: "Bruger du en gruppe ofte, kan det være en god ide at følge den. Så henter app’en automatisk data for dig og du vil modtage notifikationer fra gruppen.",
PersonelGroupInfoBody2NonTouch: "Hold musen henover en gruppe og tryk på de tre prikker for at følge, stoppe med at følge eller slå notifikationer til og fra.",
PersonelGroupInfoBody2Touch: "Swipe mod venstre på en gruppe, for at følge eller slå notifikationer til/fra.",
PersonelGroupInfoBodyTouchSymbolExplanation: "Symbolforklaring:",
PersonelGroupInfoBodyTouchSymbolExplanationFetchData: "Hent data for en gruppe uden at åbne den",
PersonelGroupInfoBodyTouchSymbolExplanationUnreadChat: "Ulæste chat og interne beskeder",
PersonelGroupInfoBodyTouchSymbolExplanationUnresolved: "Udeståender",
PersonelGroupInfoTitle: "Hent og følg personalegrupper",
PersonGroups: "Personalegrupper",
PersonGroupsNotLoaded: "Personalegrupper hvis data ikke er hentet er markeret med ikonet: ",
phone: "Telefon",
Planned: "Plan",
Planner: "Planlægger",
Problem: "Beklager, der er et problem.",
Read: "Læst",
ReadMessagesAndNotes: "Læste chat- og interne beskeder",
ReadPluralPastTense: "Læste",
RegardingShort: "Vedr.",
registerAbsence: "Registrer fravær",
Reject: "Afvis",
RejectShiftAssignment: "Bekræft afbrydelse af udbud",
RejectShiftAssignmentContent: "Er du sikker på, at du vil afbryde udbuddet i denne vagt?",
RejectShiftExchange: "Bekræft afvisning af bytte",
RejectShiftExchangeContent: "Er du sikker på, at du vil afvise dette vagtbytte?",
reload: "Genindlæs",
ResponseFromServer: "Svar fra server",
RightArrow: "Højre pil",
RuleBreak: "Regelbrud",
RuleViolationInfoApplicants: "Listen af ansøgere er sorterede efter hvor alvorligt et regelbrud der vil opstå, når en given medarbejder får vagten.\n\nRegelbruddets alvorlighed angives med et tal. Jo højere tal, jo mere alvorligt er regelbruddet.\n\n1 betyder at der ingen regelbrud opstår.",
RuleViolationInfoSortedCallList: "I den sorterede tilkaldeliste er medarbejderne sorterede efter hvor alvorligt et regelbrud der vil opstå, når en given medarbejder får vagten.\n\nRegelbruddets alvorlighed angives med et tal. Jo højere tal, jo mere alvorligt er regelbruddet.\n\n1 betyder at der ingen regelbrud opstår.",
search: "Søg",
settings: "Indstillinger",
shift_plural: "Vagter",
ShiftStatusAbsent: "Fraværende",
ShiftStatusActionRequired: "Kræver handling",
ShiftStatusNoActivities: "Arbejde uden aktiviteter",
ShiftStatusOffered: "Udbudt",
ShiftStatusPlanned: "Planlagt arbejde",
ShiftStatusReplaced: "Afløst",
ShiftStatusSubstitute: "Afløser",
somethingWentWrong: "Noget gik galt",
Status: "Status",
Tasks: "Opgaver",
Team: "Personalegrupper",
teamPlan: "TeamPlan",
TheErrorMessageWasCopied: "Fejlteksten blev kopieret til udklipsholderen",
To: "Til",
today: "I dag",
Type: "Type",
UnfollowGroup: "Følg ikke længere",
Unread: "Ulæst",
UnreadMessagesAndNotes: "Ulæste chat- og interne beskeder",
UnreadPluralPastTense: "Ulæste",
Url: "URL",
User: "Bruger",
userName: "Brugernavn",
userNameIsRequired: "Brugernavn skal udfyldes",
userNameNoBlackSpacesAtStartOrEnd: "Dit brugernavn må ikke starte eller slutte med mellemrum",
VersionNotSupported: "Version ikke understøttet",
Week: "Uge",
Weekday_Array: ["Mandag","Tirsdag","Onsdag","Torsdag","Fredag","Lørdag","Søndag"],
WeekdayInitials_Array: ["M","T","O","T","F","L","S"],
Weeks: "Uger",
WhereToFindDepartments: "Du finder personalegrupperne under \"Skift gruppe\" i bunden af skærm.",
WriteMessage: "Skriv besked",
Yes: "Ja",
YesLogout: "Ja, log ud",
YourAppIsHavingProblemsWithTheServer: "Din app har problemer med serveren",
ConfirmLeavePage: "Vil du forlade siden?",
ConfirmLeavePageContent: "Hvis du forlader siden nu, vil den vagt du var i gang med at oprette blive slettet.",
YesLeavePage: "Ja, forlad side",
ChoosePredefined: "Vælg en prædefineret vagt",
CreateNewShift: "Opret ny vagt",
CreateNew: "Opret ny",
Create: "Opret",
RuleViolationInfo: "I den sorterede tilkaldeliste er medarbejderne sorterede efter hvor alvorligt et regelbrud der vil opstå, når en given medarbejder får vagten.\n\nRegelbruddets alvorlighed angives med et tal. Jo højere tal, jo mere alvorligt er regelbruddet.\n\n1 betyder at der ingen regelbrud opstår.",
NoChangesHaveBeenMade: "Der er ikke ændret nogen vagter for denne personalegruppe.",
HowMany: "Hvor mange ulæste hentes?",
UnfollowDialogTitle: "Følg ikke længere gruppe",
UnfollowDialogBody_1: (x:string|number) => { return "Er du sikker på, at du ikke længere vil følge gruppen \"" + x + "\"?"; },
TooManyGroupsAlertTitle: "Der er ikke plads til at følge flere grupper",
TooManyGroupsAlertBody: "For at undgå at app'en bliver langsom, er der begrænsning på hvor mange medarbejdere den kan hente data på samtidig.\n\nDerfor skal du først frigøre plads ved at fjerne en af de eksisterende grupper, hvis du ønsker at tilføje en ny.\n\nVær opmærksom på at det kan være nødvendigt at fjerne flere små grupper for at gøre plads til en stor.",
ConfirmOfferOnShiftExchangeCandidateFilter: "Er du sikker på, du vil udbyde vagten i den valgte personalegruppe?",
ConfirmOfferOnShiftExchangeCandidateFilter_1: (x:string|number) => { return "Er du sikker på, du vil udbyde vagten i de " + x + " valgte personalegrupper?"; },
ConfirmOfferOnShiftExchangeAllAvailable: "Er du sikker på du vil udbyde vagten til alt ledigt personale i den valgte personalegruppe?",
ConfirmOfferOnShiftExchangeAllAvailable_1: (x:string|number) => { return "Er du sikker på du vil udbyde vagten til alt ledigt personale i de " + x + " valgte personalegrupper?"; },
ConfirmOfferExchangeCandidatesHelperText: "Filtrér på hvilke medarbejdere der ikke får vagten tilbudt.",
FilterOutEmployeesWith: "Frasortér medarbejdere med..",
responsibility: "Ansvar",
ConfirmRemoveShiftTitle: "Slet vagt",
ConfirmRemoveShiftBody_2: (x:string|number, y:string|number) => { return "Er du sikker på, at du vil slette \"" + x + "\" " + y + ""; },
ConfirmRemoveShiftBodyHandover: "Udbuddet på vagtbørsen aflyses også som følge heraf.",
RemoveShiftToast_3: (x:string|number, y:string|number, z:string|number) => { return "" + x + "s vagt \"" + y + "\" " + z + " er nu slettet."; },
DeviatingPayerNotificationTitle: "Afvigende arbejdssted",
DeviatingPayerNotificationBody_1: (x:string|number) => { return "Vær opmærksom på at denne vagt er registreret på \"" + x + "\"."; },
FindEmployee: "Find medarbejder",
IsNowAssigned_2: (x:string|number, y:string|number) => { return "" + x + " er nu tildelt " + y + "."; },
StarShift: "*-vagt",
RecentlyUsedShiftDefs: "Senest anvendt",
NoShiftMatch: "Der er ingen vagter\nder matcher din søgning.",
SelectLanguage: "Vælg sprog",
SelectShiftType: "Vælg vagttype",
SelectAbsenceType: "Vælg fraværstype",
SelectAbsenceBack: "Vælg dato",
SelectThePersonGroup: "Vælg personalegruppen",
};

const Language_Se: Language = {
    languageCode: "Se",
    capitalizeMonths: false,
    capitalizeWeekdays: false,
    languageEnum: LangLanguageEnum.Se,
AndXOtherShifts_1: (x:string|number) => { return "+ " + x + " andre vagter."; },
ChatWith_1: (x:string|number) => { return "Chat med " + x + ""; },
HandoverApproveRejectText_1: (x:string|number) => { return "Vælg den ansøger der skal have " + x + "s vagt."; },
MessageModal_1: (x:string|number) => { return "Som udgangspunkt henter TeamPlan ulæste beskeder for de kommende " + x + " dage. Dette inkluderer både chat og interne beskeder.\n\nHar du på siden ‘Overblik’ indlæst vagter der ligger udenfor dette tidsrum, vil denne periodes ulæste beskeder også vises her."; },
MessageModalRead_1: (x:string|number) => { return "Som udgangspunkt henter TeamPlan læste beskeder for de kommende " + x + " dage. Dette inkluderer både chat og interne beskeder.\n\nHar du på siden ‘Overblik’ indlæst vagter der ligger udenfor dette tidsrum, vil denne periodes læste beskeder også vises her."; },
MessageModalUnread_1: (x:string|number) => { return "Som udgangspunkt henter TeamPlan ulæste beskeder for de kommende " + x + " dage. Dette inkluderer både chat og interne beskeder.\n\nHar du på siden ‘Overblik’ indlæst vagter der ligger udenfor dette tidsrum, vil denne periodes ulæste beskeder også vises her."; },
MissingSwapGroup_1: (x:string|number) => { return "" + x + " mangler en byttegruppe"; },
NumApplicantsFromShiftExchange_1: (x:string|number) => { return "" + x + " ansøgere fra vagtbørs"; },
OfferShiftTo_1: (x:string|number) => { return "Udbyd vagt til " + x + ""; },
ConfirmShiftExchangeContent_2: (x:string|number, y:string|number) => { return "Er du sikker på, at du vil godkende vagtbytte mellem " + x + " og " + y + "?"; },
RegisteredAbsent_2: (x:string|number, y:string|number) => { return "Vær opmærksom på at du også har registreret " + x + " fraværende: " + y + ""; },
SwapApproveRejectText_2: (x:string|number, y:string|number) => { return "" + x + " og " + y + " vil gerne bytte vagt."; },
absenceType: "Frånvaro typ",
accept: "Acceptera",
Activities: "Aktiviteter",
additionalInformation: "Ytterligare information",
AddNote: "Interna meddelanden",
Alert: "Advarsel",
all: "Allt",
allEmployees: "Alla anställda",
AllowNotifications: "Tillåt meddelanden",
And1OtherShift: "+ 1 anden vagt.",
appInformation: "Appinformation",
Applicants: "Ansøgere",
Approve: "Godkänd",
ApproveRejectHandover: "Godkend/Afvis overdragelse",
ApproveRejectSwap: "Godkend/Afvis vagtbytte",
assignShift: "Tilldela pass",
auditActionAcceptShiftExchange: "Accepter vagtbytte",
auditActionCalendar: "Kalender",
auditActionCancelExchange: "Fortryd vagtbytte",
auditActionChangeTeam: "Ändra personsgrupp",
ChangeTeam: "Ändra grupp",
auditActionInit: "init",
auditActionSelectShift: "Välj pass",
auditActionToggleAllowNotifications: "Ändra Tillåt meddelanden",
AvailableFictivePerson: "Ledig fiktiv person",
AwaitingApproveHandover: "Afventer godkendelse af overdragelse",
AwaitingApproveSwap: "Afventer godkendelse af vagtbytte",
Back: "Tillbaka",
back: "Tillbaka",
callList: "Sorterad samtalslista",
Cancel: "Annullera",
CancelOffer: "Annullera erbjudande",
cancelOfferOnShiftExchange: "Avbryt pass erbjudande",
caretDown: "Nedåtpil",
ChangeOrganization: "Skift organisation",
ChangePersonelGroup: "Skift personalegruppe",
Changes: "Ändringar",
Chat: "Chat",
ChatAndInternalMessages: "Chat og interne beskeder",
ChatWithEmployee: "Chatta med anställd",
check: "Kontrollera",
chooseAbsenceType: "Välj frånvaro typ",
ChooseApplicants: "Vælg ansøgere",
ChooseGroups: "Vælg den eller de personalegrupper du vil udbyde vagten til.",
chooseLanguage: "Välj språk",
chooseTeam: "Välj personsgrupp",
clear: "Klar",
Close: "Stänga",
ConfirmCancellationOfOffer: "Bekräfta annullering av erbjudande",
ConfirmCancellationOfOfferContent: "Är du säker på att du vill återkalla detta övertagande förfrågan til allt tillgänglig personal?",
ConfirmLogout: "Vil du logge ud?",
ConfirmMarkAsHandled: "Bekräfta markerat som hanterat",
ConfirmMarkAsHandledContent: "Är du säker på att du vill markera detta som hanterat utan att hitta en ersättare?",
ConfirmOfferOnShiftExchange: "Bekräfta övertagande förfrågan",
ConfirmOfferOnShiftExchangeContent: "Är du säker på att du vill skicka detta övertagande förfrågan til allt tillgänglig personal?",
ConfirmShiftAssignment: "Bekräfta passbyte",
ConfirmShiftAssignmentContent: "Är du säker på att du vill tilldela denna pass till",
ConfirmShiftExchange: "Bekræft godkendelse af bytte",
Copy: "Kopia",
CopyDetails: "Kopia",
CopyErrorTextAndContactSupport: "Kopiera texten och kontakta IT supporten på din arbetsplats",
Date: "Dato",
Delete: "Tag bort",
Duties: "Arbetsuppgifter",
Employee: "Employee",
EndDate: "Slutdatum",
AddEndDate: "Tilføj slutdato",
enterPassword: "Skriv in lösenord",
enterUserName: "Skriv in ditt användarnamn",
Error: "Fel",
ErrorCode: "Fejlkode",
exchange: "Utbyta",
exchangeCandidateFilter: "Kandidatfilter",
absence_plural: "Fravær",
candidates: "Kandidater",
candidates_short: "Kand..",
exchangeCandidateFilter_callinEsque: "Vagter og beskyttet fravær (flere kand..)",
exchangeCandidateFilter_classic: "Vagter og fravær (færrest kandidater)",
exchangeCandidateFilter_noCallin: "Beskyttet fravær (flest kandidater)",
expectedToBeBack: "Förväntas vara tillbaka",
Eye: "Öga",
FetchData: "Hent data uden at åbne",
FictiveEmployees: "Fiktive medarbejdere",
FictivePerson: "Fiktiv person",
findSubstitute: "Hitta ersättningar",
FollowGroup: "Følg gruppe",
FormerAssignee: "Tidigare tilldelad",
From: "Från",
GoToSwap: "Gå till Pass byte",
Group: "Gruppe",
Groups: "Grupper",
GroupsFollowed: "Grupper du følger",
Hello: "Hej",
HhMmSeparator: ":",
HowManyRead: "Hvor mange læste hentes?",
HowManyUnread: "Hvor mange ulæste hentes?",
HttpStatus: "HTTP status",
Info: "Information",
InternalError: "Der skete en uventet fejl. Fejlen er rapporteret til vores driftsafdeling.",
InternalMessage: "Intern besked",
InternalMessages: "Interne beskeder",
KeepExchange: "Behold byt",
KeepOffer: "Behålla erbjudande",
Language: "Språk",
languageName: "Svensk",
LeftArrow: "Vänster pil",
Loading: "Läser in",
LoadingShifts: "Henter vagter",
loadMoreDays: "Ladda flera dagar",
Location: "Plats",
Lock: "Låsa",
login: "Logga in",
LoginLoadingData: "Lige et øjeblik,\ndin app henter data",
loginTitleWelcome: "Välkommen till",
Logout: "Logga ut",
mail: "Post",
ManyPersonGroupsContent: "Da du har adgang til mere end 10 personalegrupper, henter appen først data fra en personalegruppe når denne tilgåes.",
ManyPersonGroupsTitle: "Mange personalegrupper",
markAsHandled: "Markera som hanterat",
MaxLengthError: "Du kan ikke skrive flere tegn",
MeAlt: "Mig",
Menu: "Meny",
MessagesEmptyStateDescription_1: (x:string|number) => { return "Der er ikke modtaget eller sendt nogen beskeder i personalegruppen, der vedrører de kommende " + x + " dage."; },
MessagesEmptyStateTitle: "Ingen beskeder",
MomentLoadingData: "Øjeblik, vi henter data",
Month_Array: ["Januari","Februari","Mars","April","Maj","Juni","Juli","Augusti","September","Oktober","November","December"],
netError: "Tyvärr, det finns ett nätverksproblem, status:",
NetworkErrorOccured: "Der skete en netværksfejl",
New: "Ny",
Next: "Nästa",
No: "Nej",
NoAccessToTeamPlan: "Du har desværre ikke adgang til TeamPlan.",
noAppInformation: "Ingen App Information hittades",
NoCandidates: "Ingen oplagte kandidater",
NoCandidatesLong: "Der er desværre ingen kandidater at vise i den sorterede tilkaldeliste.",
NoChanges: "Ingen ændringer",
NoChangesHaveBeenMade_2: (x:string|number, y:string|number) => { return "Der er ikke ændret nogen vagter i perioden\n" + x + " - " + y + ""; },
noDepartmentsSelected: "Ingen personalegruppe valgt",
noDepartmentsToShow: "Inga personsgrupper att visa",
noEmployeeToShow: "Ingen anställd att visa",
NoOffersInSearch: "Der er ingen personalegrupper der matcher din søgning.",
NoPersonelGroupFollowedTextMobile: "Swipe til venstre på en gruppe og vælg",
NoPersonelGroupFollowedTextWeb: "Hold musen hen over en gruppe og tryk på de tre prikker, for at følge.",
NoPersonelGroupFollowedTitle: "Du følger ingen grupper",
Norm: "Norm",
noShiftsToShow: "Inga pass att visa",
noSortedCallListToShow: "Inga kandidater i samtalslistan",
NotAssignable: "Kan ikke tildeles",
Note: "Note",
NothingPlanned: "Intet planlagt",
NothingPlannedForGroup_2: (x:string|number, y:string|number) => { return "Der er ingen planlagte vagter i perioden\n" + x + " - " + y + ""; },
NothingToTakeActionOn_2: (x:string|number, y:string|number) => { return "Der er ingen udeståender i perioden\n" + x + " - " + y + ""; },
IsNowCreated_1: (x:string|number) => { return "" + x + " er nu oprettet."; },
notification: "Meddelande",
NotificationsOff: "Slå notifikationer fra",
NotificationsOn: "Slå notifikationer til",
NoUnresolvedIssues: "Ingen udeståender",
Offer: "Erbjudande",
offerOnShiftExchange: "Övertagande förfrågan",
OfferShift: "Erbjuda pass",
ongoingAbsence: "Löpande frånvaro",
OriginalBelongingTo: "Oprindelig tilhørende",
OtherEmployees: "Øvrige medarbejdere",
OtherGroups: "Øvrige grupper",
Overview: "Översikt",
password: "Lösenord",
passwordIsRequired: "Lösenord krävs",
Pending: "Status",
PersonelGroupInfoBody1: "Bruger du en gruppe ofte, kan det være en god ide at følge den. Så henter app’en automatisk data for dig og du vil modtage notifikationer fra gruppen.",
PersonelGroupInfoBody2NonTouch: "Hold musen henover en gruppe og tryk på de tre prikker for at følge, stoppe med at følge eller slå notifikationer til og fra.",
PersonelGroupInfoBody2Touch: "Swipe mod venstre på en gruppe, for at følge eller slå notifikationer til/fra.",
PersonelGroupInfoBodyTouchSymbolExplanation: "Symbolforklaring:",
PersonelGroupInfoBodyTouchSymbolExplanationFetchData: "Hent data for en gruppe uden at åbne den",
PersonelGroupInfoBodyTouchSymbolExplanationUnreadChat: "Ulæste chat og interne beskeder",
PersonelGroupInfoBodyTouchSymbolExplanationUnresolved: "Udeståender",
PersonelGroupInfoTitle: "Hent og følg personalegrupper",
PersonGroups: "Personalegrupper",
PersonGroupsNotLoaded: "Personalegrupper hvis data ikke er hentet er markeret med ikonet: ",
phone: "Telefon",
Planned: "Plan",
Planner: "Planlægger",
Problem: "Tyvärr, det finns ett problem.",
Read: "Læst",
ReadMessagesAndNotes: "Læste chat- og interne beskeder",
ReadPluralPastTense: "Læste",
RegardingShort: "På",
registerAbsence: "Registrera frånvaro",
Reject: "Afvis",
RejectShiftAssignment: "Bekræft afbrydelse af udbud",
RejectShiftAssignmentContent: "Er du sikker på, at du vil afbryde udbuddet i denne vagt?",
RejectShiftExchange: "Bekræft afvisning af bytte",
RejectShiftExchangeContent: "Er du sikker på, at du vil afvise dette vagtbytte?",
reload: "Ladda om",
ResponseFromServer: "Svar från server",
RightArrow: "Höger pil",
RuleBreak: "Regelbrud",
RuleViolationInfoApplicants: "Listen af ansøgere er sorterede efter hvor alvorligt et regelbrud der vil opstå, når en given medarbejder får vagten.\n\nRegelbruddets alvorlighed angives med et tal. Jo højere tal, jo mere alvorligt er regelbruddet.\n\n1 betyder at der ingen regelbrud opstår.",
RuleViolationInfoSortedCallList: "I den sorterede tilkaldeliste er medarbejderne sorterede efter hvor alvorligt et regelbrud der vil opstå, når en given medarbejder får vagten.\n\nRegelbruddets alvorlighed angives med et tal. Jo højere tal, jo mere alvorligt er regelbruddet.\n\n1 betyder at der ingen regelbrud opstår.",
search: "Sök",
settings: "Inställningar",
shift_plural: "Pass",
ShiftStatusAbsent: "Frånvarande",
ShiftStatusActionRequired: "Åtgärd krävs",
ShiftStatusNoActivities: "Planerat arbete utan aktiviteter",
ShiftStatusOffered: "Orbjuds",
ShiftStatusPlanned: "Planerat arbete",
ShiftStatusReplaced: "Ersatt",
ShiftStatusSubstitute: "Ersättning",
somethingWentWrong: "Något gick fel",
Status: "Status",
Tasks: "Uppgifter",
Team: "Personsgrupp",
teamPlan: "LagPlan",
TheErrorMessageWasCopied: "Felmeddelandet är kopierat till urklipp",
To: "Til",
today: "I dag",
Type: "Typ",
UnfollowGroup: "Følg ikke længere",
Unread: "Ulæst",
UnreadMessagesAndNotes: "Ulæste chat- og interne beskeder",
UnreadPluralPastTense: "Ulæste",
Url: "URL",
User: "Användare",
userName: "Användarnamn",
userNameIsRequired: "Användarnamn krävs",
userNameNoBlackSpacesAtStartOrEnd: "Ditt användarnamn kan inte börja eller sluta med mellanslag",
VersionNotSupported: "Versionen inte stöds",
Week: "Vecka",
Weekday_Array: ["Måndag","Tisdag","Onsdag","Torsdag","Fredag","Lördag","Söndag"],
WeekdayInitials_Array: ["M","T","O","T","F","L","S"],
Weeks: "Uger",
WhereToFindDepartments: "Du finder personalegrupperne under \"Skift gruppe\" i bunden af skærm.",
WriteMessage: "Skriv meddelande",
Yes: "Ja",
YesLogout: "Ja, log ud",
YourAppIsHavingProblemsWithTheServer: "Din app har problem med servern",
ConfirmLeavePage: "Vil du forlade siden?",
ConfirmLeavePageContent: "Hvis du forlader siden nu, vil den vagt du var i gang med at oprette blive slettet.",
YesLeavePage: "Ja, forlad side",
ChoosePredefined: "Vælg en prædefineret vagt",
CreateNewShift: "Opret ny vagt",
CreateNew: "Opret ny",
Create: "Opret",
RuleViolationInfo: "I den sorterede tilkaldeliste er medarbejderne sorterede efter hvor alvorligt et regelbrud der vil opstå, når en given medarbejder får vagten.\n\nRegelbruddets alvorlighed angives med et tal. Jo højere tal, jo mere alvorligt er regelbruddet.\n\n1 betyder at der ingen regelbrud opstår.",
NoChangesHaveBeenMade: "Der er ikke ændret nogen vagter for denne personalegruppe.",
HowMany: "Hvor mange ulæste hentes?",
UnfollowDialogTitle: "Følg ikke længere gruppe",
UnfollowDialogBody_1: (x:string|number) => { return "Er du sikker på, at du ikke længere vil følge gruppen \"" + x + "\"?"; },
TooManyGroupsAlertTitle: "Der er ikke plads til at følge flere grupper",
TooManyGroupsAlertBody: "For at undgå at app'en bliver langsom, er der begrænsning på hvor mange medarbejdere den kan hente data på samtidig.\n\nDerfor skal du først frigøre plads ved at fjerne en af de eksisterende grupper, hvis du ønsker at tilføje en ny.\n\nVær opmærksom på at det kan være nødvendigt at fjerne flere små grupper for at gøre plads til en stor.",
ConfirmOfferOnShiftExchangeCandidateFilter: "Er du sikker på, du vil udbyde vagten i den valgte personalegruppe?",
ConfirmOfferOnShiftExchangeCandidateFilter_1: (x:string|number) => { return "Er du sikker på, du vil udbyde vagten i de " + x + " valgte personalegrupper?"; },
ConfirmOfferOnShiftExchangeAllAvailable: "Er du sikker på du vil udbyde vagten til alt ledigt personale i den valgte personalegruppe?",
ConfirmOfferOnShiftExchangeAllAvailable_1: (x:string|number) => { return "Er du sikker på du vil udbyde vagten til alt ledigt personale i de " + x + " valgte personalegrupper?"; },
ConfirmOfferExchangeCandidatesHelperText: "Filtrér på hvilke medarbejdere der ikke får vagten tilbudt.",
FilterOutEmployeesWith: "Frasortér medarbejdere med..",
responsibility: "Ansvar",
ConfirmRemoveShiftTitle: "Slet vagt",
ConfirmRemoveShiftBody_2: (x:string|number, y:string|number) => { return "Er du sikker på, at du vil slette \"" + x + "\" " + y + ""; },
ConfirmRemoveShiftBodyHandover: "Udbuddet på vagtbørsen aflyses også som følge heraf.",
RemoveShiftToast_3: (x:string|number, y:string|number, z:string|number) => { return "" + x + "s vagt \"" + y + "\" " + z + " er nu slettet."; },
DeviatingPayerNotificationTitle: "Afvigende arbejdssted",
DeviatingPayerNotificationBody_1: (x:string|number) => { return "Vær opmærksom på at denne vagt er registreret på \"" + x + "\"."; },
FindEmployee: "Find medarbejder",
IsNowAssigned_2: (x:string|number, y:string|number) => { return "" + x + " er nu tildelt " + y + "."; },
StarShift: "*-vagt",
RecentlyUsedShiftDefs: "Senest anvendt",
NoShiftMatch: "Der er ingen vagter\nder matcher din søgning.",
SelectLanguage: "Vælg sprog",
SelectShiftType: "Vælg vagttype",
SelectAbsenceType: "Vælg fraværstype",
SelectAbsenceBack: "Vælg dato",
SelectThePersonGroup: "Vælg personalegruppen",
};

export const available_languages : Language[] = [
    Language_En,
    Language_Da,
    Language_Se
];

export const langMap = {
    'En': Language_En,
    'Da': Language_Da,
    'Se': Language_Se
};

export const language_enum_to_language = {
    [LangLanguageEnum.En]: Language_En,
    [LangLanguageEnum.Da]: Language_Da,
    [LangLanguageEnum.Se]: Language_Se
};