import './MenuContainer.scss';

import {
  Button,
  ButtonStyle,
  Header,
  HeaderVariant,
  IconSize,
  Icons,
  ModalVariant,
  Portal,
  SearchBar,
  TitleSize,
} from '@pdcfrontendui/components';
import { FavoriteStatusEnum, NotificationStatusEnum } from '../api/enumLib_api';
import { Period, toDefaultLoadPeriod } from '../util/dates';
import {
  attemptLoadTeamRoster,
  attemptLoadTeamRosterMulti,
} from '../ListView/ListViewActions';
import {
  attemptSetFavoriteStatus,
  attemptSetNotificationStatus,
} from './MenuActions';
import {
  attemptGetUserSettings,
  atteptGetUnreadMessagesCount,
  showConfirmDialog,
  showInfoDialog,
} from '../appActions';
import { deviceType, primaryInput } from 'detect-it';
import { useBool, useIsDualView } from '@pdcfrontendui/hooks';
import { useEffect, useMemo, useState } from 'react';

import MenuLists from './MenuLists';
import PersonelgroupInfoModal from './PersonelgroupInfoModal';
import { Store } from '../rootReducer';
import { TeamMap } from '../api/model';
import { ThunkDispatch } from '../types';

import { attemptLoadTeams } from '../ChooseTeam/ChooseTeamAction';
import classNames from 'classnames';
import colorVarMap from '@pdcfrontendui/components/colors/colors';
import { connect } from 'react-redux';
import { currentLanguage } from '../currentLanguage';
import { getSiteRoutes } from '../routes';
import { MenuItemLoadedType } from './MenuReducer';
import ids from '../testing/ids';
import { useNavigate } from 'react-router-dom';
import { tooManyGroupMembers } from './menuUtils';
import { searchString } from '@pdcfrontendui/utils';

const scss = {
  Comp: 'MenuContainer',
  shown: 'shown',
  searchContainer: 'searchContainer',
  searchInput: 'searchInput',
  headerLeftIcons: 'headerLeftIcons',
  NewMenuInfoModalBody: 'NewMenuInfoModalBody',
  isDualView: 'isDualView',
  overlayBackground: 'overlayBackground',
  noPersonelGroups: 'noPersonelGroups',
};

type Props = {
  shouldShow: boolean;
  currentTeam: string;
  currentDate: Date | null;
  onClose: () => void;
};

export type StateFromProps = {
  teams: TeamMap;
  menuItemLoading: MenuItemLoadedType;
  unreadCountMap: Record<string, number>;
  actionsRequired: Record<string, number>;
  user: string;
};

export type DispatchFromProps = {
  loadTeams: () => void;
  loadTeamRoster: (teamId: string, requestPeriod: Period) => void;
  loadTeamRosterAll: (teamIds: string[], requestPeriod: Period) => void;
  checkUnreadMessageCount: (teamIds: string[], from: Date, to: Date) => void;
  setFavoriteStatus: (
    teamId: string,
    teamLabel: string,
    status: FavoriteStatusEnum
  ) => void;
  setNotificationStatus: (
    teamId: string,
    status: NotificationStatusEnum
  ) => void;
  showTooManyMembersDialog: () => void;
  attemptGetUserSettings: () => void;
};

const mapStateToProps = (store: Store): StateFromProps => ({
  user: store.loginReducer.fullName,
  teams: store.chooseTeamReducer.teams,
  menuItemLoading: store.menuReducer.menuItemLoading,
  unreadCountMap: store.appReducer.unreadCountMap,
  actionsRequired: store.listViewReducer.actionRequiredCounters,
});

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchFromProps => ({
  loadTeams: () => dispatch(attemptLoadTeams()),
  loadTeamRoster: (teamId, requestPeriod) => {
    dispatch(attemptLoadTeamRoster(teamId, requestPeriod, false, false));
  },
  loadTeamRosterAll: (teamIds, requestPeriod) => {
    dispatch(attemptLoadTeamRosterMulti(teamIds, requestPeriod));
  },
  checkUnreadMessageCount: (teamIds, from, to) => {
    dispatch(atteptGetUnreadMessagesCount(teamIds, from, to, false));
  },
  setFavoriteStatus: (teamId, teamLabel, status) => {
    if (status === FavoriteStatusEnum.nothing) {
      dispatch(
        showConfirmDialog({
          title: currentLanguage.UnfollowDialogTitle,
          message: currentLanguage.UnfollowDialogBody_1(teamLabel),
          buttonOkText: currentLanguage.UnfollowGroup,
          buttonCancelText: currentLanguage.Cancel,
          variant: ModalVariant.Alert,
          onOk: () => {
            dispatch(attemptSetFavoriteStatus(teamId, status));
          },
        })
      );
    } else {
      dispatch(attemptSetFavoriteStatus(teamId, status));
    }
  },
  setNotificationStatus: (teamId, status) => {
    dispatch(attemptSetNotificationStatus(teamId, status));
  },
  showTooManyMembersDialog: () => {
    dispatch(
      showInfoDialog({
        title: currentLanguage.TooManyGroupsAlertTitle,
        message: currentLanguage.TooManyGroupsAlertBody,
        onOk: () => {},
        buttonText: currentLanguage.Close,
        buttonStyle: ButtonStyle.Secondary,
        variant: ModalVariant.Alert,
      })
    );
  },
  attemptGetUserSettings: () => {
    dispatch(attemptGetUserSettings());
  },
});

export const MenuContainer = ({
  teams,
  currentTeam,
  currentDate,
  shouldShow,
  menuItemLoading,
  unreadCountMap,
  actionsRequired,
  loadTeams,
  loadTeamRoster,
  loadTeamRosterAll,
  setFavoriteStatus,
  setNotificationStatus,
  checkUnreadMessageCount,
  showTooManyMembersDialog,
  attemptGetUserSettings,
  onClose,
}: Props & StateFromProps & DispatchFromProps) => {
  const navigate = useNavigate();
  const [showSearch, setShowSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isInfoShown, showInfo, hideInfo] = useBool(false);
  const isDualView = useIsDualView();
  const useTouch = deviceType === 'touchOnly' || primaryInput === 'touch';

  useEffect(() => {
    loadTeams();
    attemptGetUserSettings();
  }, []);

  const teamList = useMemo(() => Object.values(teams), [teams]);

  useEffect(() => {
    const unfollowedTeams = teamList.filter(
      (team) => team.favoriteStatus === FavoriteStatusEnum.nothing
    );
    if (
      teamList.length === unfollowedTeams.length &&
      !tooManyGroupMembers(unfollowedTeams.map((team) => team.groupMemberCount))
    ) {
      unfollowedTeams.forEach((team) => {
        setFavoriteStatus(team.id, team.label, FavoriteStatusEnum.favorite);
      });

      if (unfollowedTeams.length > 0) {
        loadTeamData(unfollowedTeams.map((team) => team.id));
      }
    } else {
      if (teamList.length > 0) {
        loadTeamData(
          teamList
            .filter(
              (team) => team.favoriteStatus === FavoriteStatusEnum.favorite
            )
            .map((team) => team.id)
        );
      }
    }
  }, [teamList]);

  const onItemSelect = (id: string) => {
    // Loading shifts for the newly selected team is handled in IntervalPolling.tsx.
    navigate(getSiteRoutes().team(id));
  };

  const loadTeamData = (ids: string[]) => {
    if (currentDate) {
      const period = toDefaultLoadPeriod(currentDate);

      if (ids.length === 1) {
        loadTeamRoster(ids[0]!, period);
      } else {
        loadTeamRosterAll(ids, period);
      }

      checkUnreadMessageCount(ids, period.from, period.to);
    }
  };

  const sortedAndFilteredTeamIds = useMemo(
    () =>
      teamList
        .filter(
          (team) =>
            searchString(searchText, team.label) ||
            searchString(searchText, team.unitUilabel)
        )
        .sort((a, b) =>
          a.label.localeCompare(b.label, undefined, {
            numeric: true,
            sensitivity: 'base',
          })
        ),
    [teamList, searchText]
  );

  return (
    <>
      <Portal>
        <>
          <div
            className={classNames(scss.Comp, {
              [scss.shown]: shouldShow,
              [scss.isDualView]: isDualView,
            })}
            id={ids.Menu.menuComponent}
            role="menu"
          >
            <Header centerize variant={HeaderVariant.Transparent}>
              <Header.Title size={TitleSize.Medium} bold>
                {currentLanguage.ChangePersonelGroup}
              </Header.Title>
              <Header.Left>
                <div className={scss.headerLeftIcons}>
                  <Icons.Info
                    onClick={() => {
                      showInfo();
                    }}
                    color={colorVarMap.colorPrimaryDark}
                  />
                  {!showSearch && (
                    <Icons.Search
                      size={IconSize.XSmall}
                      onClick={() => setShowSearch(true)}
                      color={colorVarMap.colorPrimaryDark}
                      ariaLabel={currentLanguage.search}
                    />
                  )}
                </div>
              </Header.Left>
              <Header.Right>
                <Icons.Clear
                  size={IconSize.Small}
                  onClick={() => onClose()}
                  color={colorVarMap.colorPrimaryDark}
                />
              </Header.Right>
            </Header>
            {showSearch && (
              <div className={scss.searchContainer}>
                <SearchBar
                  className={scss.searchInput}
                  value={searchText}
                  onChange={(value: string) => setSearchText(value)}
                />
                <Button
                  variant={ButtonStyle.Ghost}
                  onClick={() => {
                    setSearchText('');
                    setShowSearch(false);
                  }}
                >
                  {currentLanguage.Cancel}
                </Button>
              </div>
            )}
            {sortedAndFilteredTeamIds.length > 0 ? (
              <MenuLists
                teams={sortedAndFilteredTeamIds}
                currentTeam={currentTeam}
                actionsRequired={actionsRequired}
                unreadCountMap={unreadCountMap}
                isSearching={!showSearch}
                menuItemLoading={menuItemLoading}
                onItemClick={(teamId) => {
                  onClose();
                  onItemSelect(teamId);
                }}
                onNotificationClick={(teamId, status) => {
                  setNotificationStatus(teamId, status);
                }}
                onFavoriteClick={(team, status) => {
                  const followedTeamsCount = teamList
                    .filter(
                      (team) =>
                        team.favoriteStatus === FavoriteStatusEnum.favorite
                    )
                    .map((team) => team.groupMemberCount);

                  if (
                    status === FavoriteStatusEnum.favorite &&
                    followedTeamsCount.length > 0 &&
                    tooManyGroupMembers([
                      ...followedTeamsCount,
                      team.groupMemberCount,
                    ])
                  ) {
                    showTooManyMembersDialog();
                  } else {
                    setFavoriteStatus(team.id, team.label, status);
                  }
                }}
                onDownloadClick={(teamId) => loadTeamData([teamId])}
                useTouch={useTouch}
              />
            ) : (
              <div className={scss.noPersonelGroups}>
                <div>{currentLanguage.NoOffersInSearch}</div>
              </div>
            )}
          </div>
          {shouldShow && (
            <div
              id={ids.Menu.overlay}
              onClick={() => onClose()}
              className={scss.overlayBackground}
            />
          )}
        </>
      </Portal>
      <PersonelgroupInfoModal
        shown={isInfoShown}
        isTouch={useTouch}
        onHide={hideInfo}
      />
    </>
  );
};

export default connect<StateFromProps, DispatchFromProps, {}, Store>(
  mapStateToProps,
  mapDispatchToProps
)(MenuContainer);
